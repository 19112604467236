// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `structure-picture-area-collapsible-edit-bar {
  --animation-duration: 0.3s;

  display: block;
  max-height: 0;
  transition: max-height 0s ease 0.01s; /* we need atleast some duration so the transitionend event fires */
  z-index: 1;
}

.structure-picture-area-collapsible-edit-bar_expanded {
  max-height: 999px;
  transition: max-height 0s ease var(--animation-duration);
}

.structure-picture-area-collapsible-edit-bar--OuterAnimationContainer {
  transform: translate(0, -100%);
  transition: transform var(--animation-duration) ease;
  overflow: hidden;
  background: var(--record-it-color-gray-300);
}

.structure-picture-area-collapsible-edit-bar--InnerAnimationContainer {
  transform: translate(0, 100%);
  transition: transform var(--animation-duration) ease;
  border-top: 2px solid var(--record-it-color-gray-400);
  padding: 20px;
}

.structure-picture-area-collapsible-edit-bar_expanded
  .structure-picture-area-collapsible-edit-bar--OuterAnimationContainer,
.structure-picture-area-collapsible-edit-bar_expanded
  .structure-picture-area-collapsible-edit-bar--InnerAnimationContainer {
  transform: translate(0, 0);
}
`, "",{"version":3,"sources":["webpack://./src/structurePictureAreaComponents/structure-picture-area-collapsible-edit-bar/structure-picture-area-collapsible-edit-bar.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;;EAE1B,cAAc;EACd,aAAa;EACb,oCAAoC,EAAE,mEAAmE;EACzG,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,wDAAwD;AAC1D;;AAEA;EACE,8BAA8B;EAC9B,oDAAoD;EACpD,gBAAgB;EAChB,2CAA2C;AAC7C;;AAEA;EACE,6BAA6B;EAC7B,oDAAoD;EACpD,qDAAqD;EACrD,aAAa;AACf;;AAEA;;;;EAIE,0BAA0B;AAC5B","sourcesContent":["structure-picture-area-collapsible-edit-bar {\n  --animation-duration: 0.3s;\n\n  display: block;\n  max-height: 0;\n  transition: max-height 0s ease 0.01s; /* we need atleast some duration so the transitionend event fires */\n  z-index: 1;\n}\n\n.structure-picture-area-collapsible-edit-bar_expanded {\n  max-height: 999px;\n  transition: max-height 0s ease var(--animation-duration);\n}\n\n.structure-picture-area-collapsible-edit-bar--OuterAnimationContainer {\n  transform: translate(0, -100%);\n  transition: transform var(--animation-duration) ease;\n  overflow: hidden;\n  background: var(--record-it-color-gray-300);\n}\n\n.structure-picture-area-collapsible-edit-bar--InnerAnimationContainer {\n  transform: translate(0, 100%);\n  transition: transform var(--animation-duration) ease;\n  border-top: 2px solid var(--record-it-color-gray-400);\n  padding: 20px;\n}\n\n.structure-picture-area-collapsible-edit-bar_expanded\n  .structure-picture-area-collapsible-edit-bar--OuterAnimationContainer,\n.structure-picture-area-collapsible-edit-bar_expanded\n  .structure-picture-area-collapsible-edit-bar--InnerAnimationContainer {\n  transform: translate(0, 0);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
